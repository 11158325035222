import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import Layout from "components/Layout";
import { slugger } from "../utils/string";

import "bootstrap/dist/css/bootstrap.min.css";

const ArtistsPage = ({ pageContext }) => {
  return (
    <Layout pageName="home">
      <Helmet>
        <title>Artists Page</title>
      </Helmet>

      <ListGroup>
        {pageContext.artists.map((node) => (
          <ListGroupItem>
            <Link to={`/artist/${slugger(node.node.artistName)}`}>
              {node.node.artistName}
            </Link>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Layout>
  );
};

export default ArtistsPage;
